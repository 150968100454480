import app from './app';
import { getEnvValue, getExternalAssetFilePath } from './utils';

const defaultImageUrl = app.baseUrl + '/static/og_placeholder.png';

const meta = Object.freeze({
  promoteBlockscoutInTitle: '',
  og: {
    description: "PioneScan is powerful blockchain explorer, offering users a comprehensive view of transactions within the blockchain network. With its intuitive interface, users can effortlessly navigate through the intricate web of transactions, blocks, and addresses",
    imageUrl: defaultImageUrl,
  },
});

export default meta;